<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <div class="d-flex align-center h-full pa-0 ma-0">
              <v-img
                position="left center"
                height="100vh"
                :src="require(`@/assets/images/pages/background.jpg`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card

                class="auth-card pa-5"
              >
                <!-- logo -->
                <v-card-title class="d-flex align-center justify-center py-7">
                  <v-row class="w-full">
                    <v-col cols="7">
                      <router-link
                        to="/"
                        class="d-flex align-center"
                      >
                        <v-img
                          :src="require('@/assets/images/logos/logo.png')"
                          max-height="42px"
                          alt="logo"
                          contain
                          class="me-3 "
                        ></v-img>
                      </router-link>
                    </v-col>
                  </v-row>
                </v-card-title>

                <div v-if="!showSuccessRegistrationInfo">
                  <v-card-text>
                    <p class="text-2xl font-weight-semibold text--primary my-2">
                      {{ $t('general.welcomeMessage') }}
                    </p>
                    <p class="mb-2">
                      {{ $t('register.fillUpMessage') }}
                    </p>
                    <v-alert
                      v-if="isInvalidRegister"
                      cols="12"
                      class="mb-2"
                      type="error"
                      v-text="$t('register.invalidRegistrationData')"
                    >
                    </v-alert>
                  </v-card-text>

                  <!-- register form -->
                  <v-card-text>
                    <v-form
                      ref="registerForm"
                      @submit.prevent="handleFormSubmit"
                    >
                      <v-text-field
                        v-model="companyName"
                        outlined
                        label="Company Name"
                        :error-messages="errorMessages.companyName"
                        :rules="[validators.required]"
                        placeholder="Company Name"
                        hide-details="auto"
                        class="mb-6"
                      ></v-text-field>

                      <v-text-field
                        v-model="firstName"
                        outlined
                        label="First name"
                        :error-messages="errorMessages.firstName"
                        :rules="[validators.required, validators.alphaValidator]"
                        placeholder="First name"
                        hide-details="auto"
                        class="mb-6"
                      ></v-text-field>

                      <v-text-field
                        v-model="lastName"
                        outlined
                        label="Last name"
                        :error-messages="errorMessages.lastName"
                        :rules="[validators.required, validators.alphaValidator]"
                        placeholder="Last name"
                        hide-details="auto"
                        class="mb-6"
                      ></v-text-field>

                      <v-text-field
                        v-model="email"
                        outlined
                        :error-messages="errorMessages.email"
                        :rules="[validators.required, validators.emailValidator]"
                        label="Email"
                        placeholder="Email"
                        hide-details="auto"
                        class="mb-6"
                      ></v-text-field>

                      <v-text-field
                        v-model="password"
                        outlined
                        :type="isPasswordVisible ? 'text' : 'password'"
                        label="Password"
                        :error-messages="errorMessages.password"
                        placeholder="Password"
                        :rules="[validators.required, validators.passwordValidator]"
                        :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                        hide-details="auto"
                        class="mb-6"
                        @click:append="isPasswordVisible = !isPasswordVisible"
                      ></v-text-field>

                      <v-text-field
                        v-model="passwordConfirmation"
                        outlined
                        :type="isPasswordVisible ? 'text' : 'password'"
                        label="Password confirmation"
                        :error-messages="errorMessages.passwordConfirmation"
                        placeholder="Password confirmation"
                        :rules="[validators.required, validators.passwordValidator]"
                        :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                        hide-details="auto"
                        class="mb-6"
                        @click:append="isPasswordVisible = !isPasswordVisible"
                      ></v-text-field>

                      <v-select
                        v-model="companyType"
                        :items="[
                          {key: 'supplier', name: 'I want to make offers to tenders'},
                          {key: 'client', name: 'I want to publish tenders'}
                        ]"
                        :rules="[validators.required]"
                        :label="$t('register.companyType')"
                        item-value="key"
                        item-text="name"
                        outlined
                      >
                      </v-select>

                      <v-checkbox
                        hide-details
                        class="mt-0"
                      >
                        <template #label>
                          <div class="d-flex align-center flex-wrap">
                            <span class="me-2">{{ $t('register.userAgreement') }}</span>
                            <a href="javascript:void(0)">{{ $t('register.privacyAndTerms') }}</a>
                          </div>
                        </template>
                      </v-checkbox>

                      <v-btn
                        block
                        color="primary"
                        type="submit"
                        class="mt-6"
                      >
                        Sign Up
                      </v-btn>
                    </v-form>
                  </v-card-text>

                  <!-- create new account  -->
                  <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                    <p class="mb-0 me-2">
                      {{ $t('register.alreadyHaveAccount') }}
                    </p>
                    <router-link :to="{name:'auth-login'}">
                      {{ $t('register.signInInstead') }}
                    </router-link>
                  </v-card-text>

                  <!-- divider -->
                  <v-card-text class="d-flex align-center mt-2">
                    <v-divider></v-divider>
                    <span class="mx-5">{{ $t('general.or') }}</span>
                    <v-divider></v-divider>
                  </v-card-text>

                  <!-- social links -->
                  <v-card-actions class="d-flex justify-center">
                    <v-btn
                      v-for="link in socialLink"
                      :key="link.icon"
                      icon
                      class="ms-1"
                    >
                      <v-icon :color="$vuetify.theme.dark ? link.colorInDark:link.color">
                        {{ link.icon }}
                      </v-icon>
                    </v-btn>
                  </v-card-actions>
                </div>

                <v-alert
                  v-if="showSuccessRegistrationInfo && !isInvalidRegister"
                  type="success"
                >
                  {{ $t('register.successfullyRegistered') }}
                </v-alert>

                <v-col
                  v-if="showSuccessRegistrationInfo && !isInvalidRegister"
                  cols="12"
                >
                  {{ $t('register.resendEmailMessage') }}
                  <v-btn
                    dense
                    color="primary"
                    @click.prevent="resendEmail"
                  >
                    {{ $t('register.resend') }}
                  </v-btn>
                </v-col>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOffOutline, mdiEyeOutline, mdiFacebook, mdiGoogle, mdiTwitter } from '@mdi/js'
// eslint-disable-next-line object-curly-newline
import { alphaValidator, emailValidator, passwordValidator, required } from '@core/utils/validation'
import { ref } from '@vue/composition-api'
import axiosIns from '@axios'
import themeConfig from '@themeConfig'

export default {

  setup() {
    // Template Refs
    const registerForm = ref(null)

    const showSuccessRegistrationInfo = ref(false)
    const isInvalidRegister = ref(false)
    const isPasswordVisible = ref(false)
    const companyName = ref('')
    const firstName = ref('')
    const lastName = ref('')
    const email = ref('')
    const password = ref('')
    const passwordConfirmation = ref('')
    const companyType = ref('')
    const errorMessages = ref([])
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    const resendEmail = () => {
      axiosIns.post('email-verify-resend', {
        email: email.value,
      }).then(() => {

      }).catch(() => {

      })
    }

    const handleFormSubmit = () => {
      const isFormValid = registerForm.value.validate()

      if (!isFormValid) return

      axiosIns
        .post('/auth/register', {
          company: companyName.value,
          first_name: firstName.value,
          last_name: lastName.value,
          email: email.value,
          password: password.value,
          password_confirmation: passwordConfirmation.value,
          company_type: companyType.value,
        })
        .then(response => {
          if (response.data) {
            showSuccessRegistrationInfo.value = true
            isInvalidRegister.value = false
          }

          return true
        })
        .catch(error => {
          if (+error.response.status === 422) {
            isInvalidRegister.value = true
            showSuccessRegistrationInfo.value = false
          }
        })
    }

    return {
      companyType,
      showSuccessRegistrationInfo,
      isInvalidRegister,
      isPasswordVisible,
      companyName,
      firstName,
      lastName,
      email,
      password,
      passwordConfirmation,
      errorMessages,
      resendEmail,
      handleFormSubmit,
      socialLink,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
        passwordValidator,
        alphaValidator,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      registerForm,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
